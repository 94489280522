import { useContext } from "react";
import { observer } from "mobx-react";

import ReportBlock from "@components/Reports/ReportBlock/ReportBlock";
import { TItemRendererArgs } from "@components/Shared/SortableLinkedLists/Components/Item/Item";
import DragToSelectContext from "@providers/DragToSelect/DragToSelectContext";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IReportBlock } from "@store/ReportBlockStore";

export interface IReportBlockSortableListItemOwnProps {
  hasSingleBlock: boolean;
  onAddReportBlock(block: IReportBlock, type?: RollupEditorType.p, label?: string, above?: boolean): void;
}

interface IReportBlockSortableListItemProps extends IReportBlockSortableListItemOwnProps, TItemRendererArgs {}

const ReportBlockSortableListItem = (props: IReportBlockSortableListItemProps) => {
  const { listeners, handleProps, dragging, value: reportBlockId, selected, hasSingleBlock, onAddReportBlock } = props;
  const reportBlock = appStore.workspaceModel?.reportBlocksMap.get(reportBlockId);
  const { resetSelection } = useContext(DragToSelectContext);

  if (!reportBlock?.parentReport) {
    return null;
  }

  return (
    <ReportBlock
      report={reportBlock.parentReport}
      onFocus={resetSelection}
      selected={selected}
      dragListeners={{ ...listeners, ...handleProps }}
      isDragging={dragging}
      key={reportBlockId}
      reportBlock={reportBlock}
      hasSingleBlock={hasSingleBlock}
      onAddReportBlock={onAddReportBlock}
    />
  );
};

export default observer(ReportBlockSortableListItem);
