import { Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import BlockFilterableMenu from "@components/Shared/BlockFilterableMenu";
import { Switch } from "@components/Switch";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IReport } from "@store/ReportsStore";
import { EntityType } from "@store/types";

import styles from "./ReportActionsMenu.module.scss";

type Props = {
  report: IReport;
};

const ReportActionsMenu = (props: Props) => {
  const { report } = props;

  const toggleFullWidth = () => {
    report.update({ fullWidth: !report.fullWidth });
  };

  const onAddToBlockAttachments = (block: IBlock) => {
    block.addReferenceLink({
      entityId: report.id,
      entityType: EntityType.Report,
      label: report.label,
      workspaceId: appStore.workspaceModel?.id,
    });
  };

  return (
    <Menu>
      <Switch
        className={styles.reportActionsMenuSwitch}
        checked={!!report.fullWidth}
        label="Full width"
        onChange={toggleFullWidth}
        alignIndicator="right"
        e2eIdentifiers="full-width"
      />
      <MenuDivider />
      <MenuItem
        onClick={() => appStore.ui.setCreateReportTemplateDialog(report.id)}
        icon="add"
        text="Create template"
        e2eIdentifiers="create-template"
      />
      <MenuItem icon="paperclip" text="Add to Attachments" e2eIdentifiers="create-template">
        <BlockFilterableMenu onSelect={onAddToBlockAttachments} />
      </MenuItem>
      <MenuItemDelete onDelete={appStore.ui.showDeleteReportConfirmationDialog} />
    </Menu>
  );
};

export default observer(ReportActionsMenu);
