import { useState } from "react";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import { Text, TextVariant } from "@ui/Text";
import { observer } from "mobx-react";

import { ProjectManagementIcon } from "@components/CustomIcon";
import DialogCreateProject from "@components/Dialog/DialogCreateProject";
import { PlusButton } from "@components/PlusButton";
import { ICreateProjectDto } from "@rollup-api/models/pm/project.dto";
import { IconSource } from "@rollup-types/icons";
import { EPageName } from "@router/hooks/useAppNavigate";
import NavLinkAsMenuItem from "@router/navigation/NavLinkAsMenuItem";
import appStore from "@store/AppStore";

import styles from "./SidePanelProjectList.module.scss";

interface IProject {
  id: string;
  label: string;
}

const SidePanelProjectList = () => {
  const [isCreateProjectDialogOpen, setIsCreateProjectDialogOpen] = useState(false);
  const { projects } = appStore.projectManagement;

  const handleCreateProject = (dto: ICreateProjectDto) => {
    appStore.projectManagement.createProject(dto);
    setIsCreateProjectDialogOpen(false);
  };

  const renderProjectItem = (project: IProject) => {
    return (
      <NavLinkAsMenuItem
        className={styles.projectItem}
        icon={{ source: IconSource.Custom, name: ProjectManagementIcon.Project }}
        text={project.label}
        to={`/${EPageName.ProjectManagement}/${EPageName.ProjectManagementProjects}/${project.id}`}
        e2eIdentifiers={["project", project.label]}
        key={project.label}
        renderRightElements={className => <BlueprintIcon className={className} icon="chevron-right" />}
      />
    );
  };

  return (
    <div className={styles.sidePanelProjectList}>
      <DialogCreateProject
        isOpen={isCreateProjectDialogOpen}
        onCancel={() => setIsCreateProjectDialogOpen(false)}
        onCreate={handleCreateProject}
      />
      <div className={styles.header}>
        <Text variant={TextVariant.H5}>Your Projects</Text>
        <PlusButton onClick={() => setIsCreateProjectDialogOpen(true)} />
      </div>
      <div className={styles.projectsContainer}>{projects.map(renderProjectItem)}</div>
    </div>
  );
};

export default observer(SidePanelProjectList);
