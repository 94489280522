import { useState } from "react";
import { H4, Icon, InputGroup, Intent, Label, Radio, RadioGroup, TextArea } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import useCatalogItemPartNumber from "@hooks/useCatalogItemPartNumber";
import { SelectItem } from "@ui/Select/Item/SelectItem";
import { v4 as uuidV4 } from "uuid";

import { Button } from "@components/Button";
import CatalogItemCostPicker from "@components/CatalogItemCostPicker";
import CatalogItemLeadTimePicker from "@components/CatalogItemLeadTimePicker";
import CatalogItemStatusSelect from "@components/CatalogItemStatusSelect";
import CatalogItemWeightPicker from "@components/CatalogItemWeightPicker";
import { FILE_IMPORT_LIMIT } from "@components/CreateCatalogItemDialog/constants";
import FileDropZone, { EFileFormat } from "@components/FileDropZone";
import ImageUploaderCard from "@components/ImageUploaderCard";
import AttachmentIcon from "@components/Modeling/ModelingFrame/ModelBlock/Attachments/AttachmentIcon";
import PartNumber from "@components/PartNumber";
import PositiveNumberInput from "@components/PositiveNumberInput/PositiveNumberInput";
import { CatalogItemSource, CatalogItemStatus, CatalogItemType } from "@rollup-api/models/catalogItem/catalogItemDtos";
import appStore from "@store/AppStore";
import { getMimeType } from "@utilities/FileTypes";

import "./ManualCreate.scss";

const ManualCreate = () => {
  const [itemSource, setItemSource] = useState<CatalogItemSource>(CatalogItemSource.InHouse);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [type, setType] = useState<CatalogItemType>(CatalogItemType.Part);
  const [name, setName] = useState("");
  const [status, setStatus] = useState<CatalogItemStatus>(CatalogItemStatus.Pending);
  const [material, setMaterial] = useState("");
  const [weight, setWeight] = useState("0");
  const [weightUnit, setWeightUnit] = useState("kg");
  const [imageUrl, setImageUrl] = useState("");
  const [cost, setCost] = useState("0");
  const [costCurrency, setCostCurrency] = useState("$");
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [supplier, setSupplier] = useState("");
  const [leadTime, setLeadTime] = useState("");
  const [description, setDescription] = useState("");
  const { partNumber, partNumberSchemaId, handleDeleteSchema, handleSetFromSchema, handleSetFromString, handleAddNewSchema } =
    useCatalogItemPartNumber("");

  const handleAddImage = (url: string) => {
    setImageUrl(url);
    setIsUploadingImage(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let attachmentId: string | undefined;
    if (file) {
      attachmentId = await appStore.orgModel.attachments.add({ label: file.name }, file);
    }
    appStore.orgModel.catalogItems
      .createCatalogItem({
        id: uuidV4(),
        name,
        type,
        partNumber,
        status,
        weightUnit,
        costCurrency,
        weight: Number(weight),
        material,
        cost: Number(cost),
        quantity,
        supplier,
        description,
        imageUrl,
        leadTime,
        partNumberSchemaId,
        attachmentId,
        workspaceId: appStore.workspaceModel?.id,
      })
      .then(appStore.ui.hideCatalogItemCreationModal)
      .finally(() => setLoading(false));
  };

  const canSubmit = () => {
    return name && partNumber && leadTime;
  };

  const handleFileDrop = (fileList: FileList) => {
    if (fileList.length) {
      setFile(fileList[0]);
    } else {
      setFile(null);
    }
  };

  const renderFileContent = () => {
    return (
      <div>
        {file ? (
          <div className="manual-create--file">
            <div className="manual-create--file-label">
              <AttachmentIcon mimeType={getMimeType(file)} />
              <div className="manual-create--file-name">{file.name}</div>
            </div>
            <Button minimal icon="cross" onClick={() => setFile(null)} e2eIdentifiers="remove-file" />
          </div>
        ) : (
          <div className="manual-create--file-dropzone">
            <Icon icon="paperclip" size={18} />
            <div>Select CAD file to upload</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="manual-create">
      <div className="manual-create--content">
        <H4>Manually</H4>
        <div className="create-catalog-item-dialog--row">
          <Label>
            <span>Part number</span>
            <PartNumber
              asInput
              onSchemaDelete={handleDeleteSchema}
              partNumberSchemas={appStore.orgModel.partNumberSchemas.values}
              partNumber={partNumber}
              onAddNewSchema={handleAddNewSchema}
              onUpdateFromString={handleSetFromString}
              onUpdateFromSchema={handleSetFromSchema}
            />
          </Label>
          <Label>
            <span>Part name</span>
            <InputGroup value={name} onChange={e => setName(e.target.value)} className="manual-create--part-name" />
          </Label>
          <Label className="manual-create--part-type">
            <span>Part type</span>
            <Select<CatalogItemType>
              filterable={false}
              items={Object.values(CatalogItemType)}
              popoverProps={{ minimal: true }}
              onItemSelect={setType}
              itemRenderer={(item, { handleClick }) => (
                <SelectItem
                  className="manual-create--select-item"
                  slug={item}
                  key={item}
                  label={item}
                  onClick={handleClick}
                  active={type === item}
                />
              )}
            >
              <Button
                fill
                alignText="left"
                className="manual-create--select-btn"
                text={type}
                rightIcon="chevron-down"
                e2eIdentifiers="select-part-type-button"
              />
            </Select>
          </Label>
          <CatalogItemStatusSelect status={status} onStatusChange={setStatus} />
        </div>
        <div className="create-catalog-item-dialog--row">
          <Label>
            <span>Primary Material</span>
            <InputGroup value={material} onChange={e => setMaterial(e.target.value)} className="manual-create--material" />
          </Label>
          <CatalogItemCostPicker cost={cost} onCurrencyChange={setCostCurrency} currency={costCurrency} onCostChange={setCost} />
          <CatalogItemWeightPicker weight={weight} onWeightChange={setWeight} onUnitChange={setWeightUnit} unit={weightUnit} />
        </div>
        <div className="create-catalog-item-dialog--row">
          <Label>
            <span>Supplier</span>
            <InputGroup value={supplier} onChange={e => setSupplier(e.target.value)} className="manual-create--supplier" />
          </Label>
          <Label className="manual-create--invetory">
            <span>Inventory</span>
            <PositiveNumberInput className="manual-create--quantity" value={quantity} onChange={setQuantity} />
          </Label>
          <CatalogItemLeadTimePicker onLeadTimeChange={setLeadTime} defaultValue={leadTime} />
        </div>
        <div>
          <RadioGroup
            className="manual-create--radio-group"
            onChange={e => setItemSource(e.currentTarget.value as CatalogItemSource)}
            selectedValue={itemSource}
          >
            {Object.values(CatalogItemSource).map(source => (
              <Radio key={source} label={source} value={source} />
            ))}
          </RadioGroup>
        </div>
        <div className="create-catalog-item-dialog--row">
          <div>
            <Label>
              Description
              <TextArea value={description} onChange={e => setDescription(e.target.value)} className="manual-create--description" />
            </Label>
            <FileDropZone
              sizeLimit={FILE_IMPORT_LIMIT}
              format={[EFileFormat.PRT, EFileFormat.SLDASM]}
              clickToSelect
              onChange={handleFileDrop}
            >
              {renderFileContent}
            </FileDropZone>
          </div>
          <ImageUploaderCard
            src={imageUrl}
            height={208}
            imageClassName="manual-create--image"
            containerClassName="manual-create--image-container"
            onUploadStart={() => setIsUploadingImage(true)}
            onSrcChange={handleAddImage}
          />
        </div>
      </div>
      <div className="manual-create--footer">
        <Button onClick={appStore.ui.hideCatalogItemCreationModal} large minimal e2eIdentifiers="cancel-catalog-item-creation">
          Cancel
        </Button>
        <Button
          disabled={!canSubmit() || isUploadingImage}
          large
          loading={loading}
          style={{ width: 80 }}
          intent={Intent.PRIMARY}
          onClick={handleSubmit}
          e2eIdentifiers="manual-submit-catalog-item"
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default ManualCreate;
