import { resolveEntity } from "@rollup-io/engineering";

import appStore from "@store/AppStore";
import { IAttachment } from "@store/AttachmentStore";
import { getWorkspaceById } from "@utilities/Workspace";

export const getAttachmentById = async (id: string, workspaceId?: string) => {
  const workspace = await getWorkspaceById(workspaceId);
  return resolveEntity<IAttachment>(workspace?.attachments.attachmentMap, id);
};

export const getAttachmentByIdFromCurrentWorkspace = (id: string) => {
  return resolveEntity<IAttachment>(appStore?.workspaceModel?.attachments.attachmentMap, id);
};
