import { ParentClient } from "../client";

import { Projects } from "./projects";
import { ProjectTasks } from "./projectTasks";

export class PmModule {
  public projects: Projects;
  public projectTasks: ProjectTasks;

  public constructor(parent: ParentClient) {
    this.projects = new Projects(parent);
    this.projectTasks = new ProjectTasks(parent);
  }
}
