import { EntityModelBase } from "../../shared/entityModelBase";

export class OauthApp extends EntityModelBase {
  public readonly label!: string;
  public readonly applicationName!: string;
  public readonly logoUrl?: string;
  public readonly clientId!: string;
  public readonly clientSecret!: string;
  public readonly developerUrl?: string;
  public readonly developerName?: string;
  public readonly description?: string;
  public readonly callbackUrls!: string[];
}
